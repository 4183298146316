import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/seo'
import _ from 'lodash'

const ArchivePage = ({ data }) => {
  const posts = data.allMarkdownRemark.edges

  // Group posts by year
  const postsByYear = _.groupBy(posts, ({ node }) => {
    return new Date(node.frontmatter.date).getFullYear()
  })

  // Sort years in descending order
  const years = Object.keys(postsByYear).sort((a, b) => b - a)

  return (
    <Layout>
      <Seo title="Archive" />
      <h2 className="text-4xl font-bold mb-6 border-b pb-4 text-blue-800">Archive</h2>
      <div className="space-y-8">
        {years.map(year => (
          <div key={year} className="bg-white shadow-md rounded-lg overflow-hidden border-2 border-blue-200 p-6">
            <h3 className="text-2xl font-bold mb-4 text-blue-700">{year}</h3>
            <ul className="space-y-2">
              {postsByYear[year].map(({ node }) => (
                <li key={node.fields.slug}>
                  <Link 
                    to={node.fields.slug} 
                    className="text-blue-600 hover:text-blue-800 transition-colors duration-300"
                  >
                    {node.frontmatter.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </Layout>
  )
}

export default ArchivePage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            date(formatString: "YYYY-MM-DD")
            title
          }
        }
      }
    }
  }
`
